<script setup>
import { ref, provide, computed, watch } from 'vue';
import { isEqual, pickBy } from 'lodash';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    as: {
        type: String,
        default: 'div',
    },
    by: {
        type: Function,
        default: isEqual,
    },
    modelValue: {
        type: [Object, Array],
        default: () => [],
    },
    defaultValue: {
        type: [Object, Array],
        default: () => null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const optionId = ref(0);
const internalValue = ref(props.defaultValue ?? props.modelValue);
const defaultValues = computed(() => {
    if (!props.modelValue.length) {
        return internalValue.value;
    }

    return props.modelValue;
});

const selections = ref({});
const active = ref({});
const valueMap = ref({});

const isSelected = (uid) => {
    return uid in selections.value;
};
const isActive = (uid) => {
    return uid in active.value;
};

const emitSelections = () => {
    internalValue.value = props.modelValue;
    emit('update:modelValue', Object.values(selections.value));
};

provide(Symbol.for('CheckboxGroup'), {
    defaultValues,
    isSelected,
    isActive,
    registerOption(value) {
        const uid = optionId.value++;
        valueMap.value[uid] = value;

        for (const index in defaultValues.value) {
            const selectedValue = defaultValues.value[index];
            if (props.by(value, selectedValue)) {
                selections.value[uid] = value;
            }
        }

        return uid;
    },
    select(uid) {
        if (isSelected(uid)) {
            delete selections.value[uid];
        } else {
            selections.value[uid] = valueMap.value[uid];
        }

        emitSelections();
    },
    markActive(uid) {
        Object.keys(active.value).forEach((key) => delete active.value[key]);
        active.value[uid] = valueMap.value[uid];
    },
});

watch(
    () => props.modelValue,
    (newValue) => {
        console.log('update checkbox group');
        selections.value = pickBy(valueMap.value, (x) => newValue.some((y) => props.by(x, y)));
    },
);
</script>

<template>
    <component :is="as">
        <slot></slot>
    </component>
</template>
